import React from "react";
import styles from "./index.module.scss";
import contactUs from "../../assets/images/contact-us.png";
import FormInput from "../../components/form-input";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import x from "../../assets/icons/x.svg";
import { useHookForm } from "../../hooks/hook-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/slices/snackbarSlice";
import arrow from '../../assets/icons/right-arrow-white.svg';

const ContactUs = () => {
  const {
    fullNameRegister,
    emailRegister,
    phoneRegister,
    messageRegister,
    errors,
    handleSubmit,
    watch,
    isValid,
    setValue,
  } = useHookForm({
    isFullName: true,
    isEmail: true,
    isMessage: true,
    isPhone: true,
  });

  const dispatch = useDispatch();

  const submitForm = async (data) => {
    try {
      await axios.post(
        "http://saja-academy.uniparticle.com/api/content/contact/create",
        {
          name: data.full_name,
          email: data.email,
          phone_number: data.mobile_number,
          message: data.message,
        }
      );
      setValue("full_name", "");
      setValue("email", "");
      setValue("mobile_number", "");
      setValue("message", "");
      dispatch(
        setSnackbar({
          display: true,
          variant: "success",
          message: "Your message has been sent successfully.",
        })
      );
    } catch (err) {
      dispatch(
        setSnackbar({
          display: true,
          variant: "error",
          message: err.message,
        })
      );
    }
  };
  return (
    <div className={styles["contact-us"]}>
      <div className={styles["contact-us-content"]}>
        <div className={styles["contact-us-content-form-container"]}>
          <form noValidate onSubmit={handleSubmit(submitForm)}>
            <FormInput
              label={"Name"}
              name="full_name"
              error={errors?.["full_name"]}
              formRegister={fullNameRegister}
              value={watch("full_name")}
            />
            <FormInput
              label={"E-mail"}
              name="email"
              error={errors?.["email"]}
              formRegister={emailRegister}
              value={watch("email")}
            />
            <FormInput
              label={"Phone number"}
              name="mobile_number"
              error={errors?.["mobile_number"]}
              formRegister={phoneRegister}
              max={11}
              value={watch("mobile_number")}
            />
            <FormInput
              label={"Your message"}
              name="message"
              error={errors?.["message"]}
              value={watch("message")}
              formRegister={messageRegister}
            />

            <button
              type="submit" disabled={!isValid}
              className={styles['about-action']}>
              <span className={styles['about-action-text']}>Send</span>
              <span className={styles['about-action-icon']}>
                <img src={arrow} className={styles['about-action-arrow']} alt='' />
              </span>
            </button>
          </form>
          <div className={styles["contact-us-content-form-container-footer"]}>
            <div
              className={styles["contact-us-content-form-container-footer-col"]}
            >
              <h4>Location</h4>
              <ul>
                <li>Cairo</li>
                <li>Dubai</li>
                <li>Riyadh</li>
              </ul>
            </div>
            <div
              className={styles["contact-us-content-form-container-footer-col"]}
            >
              <h4>Contact</h4>
              <div
                className={
                  styles["contact-us-content-form-container-footer-col-info"]
                }
              >
                <a href="tel:+201111971970">(+20) 111 197 1970</a>
                <a href="mailto:Contact.us@saja-edu.com">
                  Contact.us@saja-edu.com
                </a>
              </div>
            </div>
            <div
              className={styles["contact-us-content-form-container-footer-col"]}
            >
              <a
                href="https://www.facebook.com/share/z5qbeWBkJsbuiabA/?mibextid=qi2Omg"
                target="_blank"
                className={styles["footer-social-icon"]}
              >
                <img src={facebook} alt="" />
              </a>
              <a
                href="https://www.instagram.com/saja_education?igsh=MXNzcjRkaXRtcGxzZA=="
                target="_blank"
                className={styles["footer-social-icon"]}
              >
                <img src={instagram} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/saja-education/"
                target="_blank"
                className={styles["footer-social-icon"]}
              >
                <img src={linkedin} alt="" />
              </a>
              <a
                href="https://x.com/SajaEduServices?s=08"
                target="_blank"
                className={styles["footer-social-icon"]}
              >
                <img src={x} alt="" />
              </a>
            </div>
          </div>
        </div>
        <img src={contactUs} alt="contact us" />
      </div>
      <div className={styles["copyrights"]}>
        <p>All copyrights reserved (C) 2024</p>
        <p>
          made with passion by{" "}
          <a href="https://www.uniparticle.com/" target="_blank">
            uniparticle
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
