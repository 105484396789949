import styles from './index.module.scss';
import cardOne from '../../assets/images/card-one.webp';
import cardTwo from '../../assets/images/card-two.webp';
import cardThree from '../../assets/images/card-three.webp';
import arrow from '../../assets/icons/right-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';

export default function Cards() {
  const navigate = useNavigate()

  return (
    <section className={styles['cards']}>
      <div className={styles['cards-image-card']}>
        <img src={cardOne} className={styles['cards-image-card-image']} alt='' />
      </div>
      <div className={styles['cards-card']}>
        <div className={styles['cards-card-content']}>
          <h3 className={styles['cards-card-title']}>Student Centric Approach</h3>
          <p className={styles['cards-card-desc']}>Saja embraces a child-centric approach, placing the needs, interests, and well-being of each child at the center of their educational philosophy.</p>
          <HashLink smooth to="/our-services#approach">
            <button className={styles['cards-card-action']}>
              <img src={arrow} className={styles['cards-card-action-image']} alt='' />
            </button>
          </HashLink>
        </div>
      </div>
      <div className={styles['cards-image-card']}>
        <img src={cardTwo} className={styles['cards-image-card-image']} alt='' />
      </div>
      <div className={styles['cards-card']}>
        <div className={styles['cards-card-content']}>
          <h3 className={styles['cards-card-title']}>Partnership with Parents</h3>
          <p className={styles['cards-card-desc']}>Recognizing that parents are a child’s first and most influential teachers, Saja fosters close collaboration between educators and families to ensure that each child receives the best possible support for their development.</p>
          <HashLink smooth to="/our-services#partnership">
            <button className={styles['cards-card-action']}>
              <img src={arrow} className={styles['cards-card-action-image']} alt='' />
            </button>
          </HashLink>
        </div>
      </div>
      <div className={styles['cards-image-card']}>
        <img src={cardThree} className={styles['cards-image-card-image']} alt='' />
      </div>
      <div className={styles['cards-card']}>
        <div className={styles['cards-card-content']}>
          <h3 className={styles['cards-card-title']}>Play-based Learning</h3>
          <p className={styles['cards-card-desc']}>At Saja Education Services, play-based learning is a core component of their educational approach, reflecting their commitment to fostering holistic development in young children.</p>
          <HashLink smooth to="/our-services#learning">
            <button className={styles['cards-card-action']}>
              <img src={arrow} className={styles['cards-card-action-image']} alt='' />
            </button>
          </HashLink>
        </div>
      </div>
    </section>
  )
}
